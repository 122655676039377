<template>
  <div>
    <div class="d-flex pb-2 pt-2 mt-4 align-center justify-center animate__animated animate__zoomIn blue--text heading">
      Blog Articles
    </div>
    <v-row class="px-2" style="position: relative;">
      <v-sheet class="d-flex" style="overflow-x: scroll;" id="sheet">
        <v-icon class="rounded blue lighten-4" style="position: absolute; left: 1rem; top: 50%; z-index: 99;" @click="scrollTheSheetToLeft">
          mdi-arrow-left
        </v-icon>
        <v-col class="col-12">
          <howtobuyCard />
        </v-col>
        <v-col class="col-12">
          <redFlagCard />
        </v-col>
        <v-col class="col-12">
          <blog-card />
        </v-col>
        <v-col class="col-12">
          <inflation-blog />
        </v-col>
        <v-col class="col-12">
          <landLocationCard />
        </v-col>
        <v-icon class="rounded blue lighten-4" style="position: absolute; right: 1rem; top: 50%; z-index: 99;" @click="scrollTheSheetToRight">
          mdi-arrow-right
        </v-icon>
      </v-sheet>
    </v-row>
  </div>
</template>
<script>
  import blogCard from "./blogCard.vue"
  import InflationBlog from "./inflationBlog.vue";
  import landLocationCard from "./landLocationCard.vue";
  import redFlagCard from "./redFlagCard.vue";
  import howtobuyCard from "./howtobuyCard.vue";
  export default{
    name: 'blogsSection',
    components:{
      blogCard, InflationBlog, landLocationCard,
      redFlagCard, howtobuyCard
    },
    methods:{
      viewAll(){
        this.$router.push('/blogs')
      },

      scrollTheSheetToRight(){
        let sheet = document.getElementById('sheet')
        for (let index = 0; index < 250; index++) {
          setTimeout(() => {
            if(index/2 > 0.5){
              sheet.scrollLeft += 2

            } else {
              sheet.scrollLeft += 1
            }
          }, index*3);
        }
      },

      scrollTheSheetToLeft(){
        let sheet = document.getElementById('sheet')
        for (let index = 0; index < 250; index++) {
          setTimeout(() => {
            if(index/2 > 0.5){
              sheet.scrollLeft -= 2

            } else {
              sheet.scrollLeft -= 1
            }
          }, index*2);
        }
      }
    }
  }
</script>
