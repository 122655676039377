<template lang="html">
  <div style="padding-bottom: 5rem;">
    <!-- <div class="d-flex justify-center animate__animated animate__zoomIn" style="font-size: 2.3rem; color: red;">
      Book site visit on propertyxyz
    </div>
     -->
    <div class="d-flex justify-center align-center animate__animated animate__zoomIn mt-4" style="font-size: 2rem; color: red;">
      Book Site Visit
      <!-- <v-btn x-small text class="blue--text" @click="viewAll">
        view all
      </v-btn> -->

    </div>
    <v-row class="no-gutters mb-3">
      <v-col class="col-12 d-flex justify-center align-center pa-4" style="border-right: solid 3px white;">
        Our team is available to take you to the site on the following days:
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <h3 class="red--text d-flex align-center">
          <v-icon class="red rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Saturday
        </h3> 
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <h3 class="red--text d-flex align-center">
          <v-icon class="red rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Sunday
        </h3>
      </v-col>
      <v-col class="col-12 d-flex justify-center">
        <v-form ref="form" class="" style="width: 90%;"
        v-model="valid"
        :lazy-validation="lazy">
          <p class="text--center"> 
            Fill the form below to book the site visit. Our team will get in touch with you to make the arrangements 
          </p>
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="name"
          v-model="contact_data.name"
          label="name"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="email"
          v-model="contact_data.email"
          label="email"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="phone number"
          v-model="contact_data.phone_number"
          label="phone number"
          type="number"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="pick the date you are available"
          v-model="contact_data.dates"
          label="availability"
          type="name"
          outlined
          clearable />
          <v-textarea
          placeholder="additional comment"
          :rules="rules.requiredRule"
          v-model="contact_data.message"
          label="additional commment"
          type="name"
          outlined
          clearable />
          <div  class="d-flex justify-end">
            <v-btn class="success mb-4" @click="book" :disabled="!valid"> book </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'book',
  data(){
    return{
      contact_data: {},
      valid: true,
      lazy: false,
      rules: {
        requiredRule: [
          v => !!v || 'This field is required!',
        ]
      } 
    }
  },
  methods: {
    async book(){
      try{
        console.log(this.book_data)
        this.book_loading = true
        const response = await
        axios.post('https://api.adilirealestate.com/api/book', this.book_data).then((response) => {
          console.log(response)
          const alert_box_info = {
            status: true,
            information: 'Site visit recorded successfully, we shall get in touch as soon as possible.',
            code: 'success'
          }
          this.toogleAlertBox(alert_box_info)
          this.book_data = {}
          this.book_loading = false
        })
        console.log(response)
      } catch(e){
        console.log(e)
      }
    }
      
  }
}
</script>
