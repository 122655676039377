<template>
  <div class="px-2">
    <div class="d-flex align-center justify-center animate__animated animate__zoomIn heading blue--text">
      Blogs
    </div>
    <v-row>
      <v-col class="col-12">
        <howtobuyCard />
      </v-col>
      <v-col class="col-12">
        <redFlagCard />
      </v-col>
      <v-col class="col-12">
        <blog-card />
      </v-col>
      <v-col class="col-12">
        <inflation-blog />
      </v-col>
      <v-col class="col-12">
        <landLocationCard />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import blogCard from "../components/home/blogCard.vue"
import inflationBlog from "../components/home/inflationBlog.vue"
import landLocationCard from "../components/home/landLocationCard.vue"
import redFlagCard from "../components/home/redFlagCard.vue"
import howtobuyCard from "../components/home/howtobuyCard.vue"
  export default {
    name: 'Blogs',
    components:{
      blogCard, inflationBlog, landLocationCard, redFlagCard, howtobuyCard
    }
  }
</script>
