<template>
  <div class="pale-blue">
    <div class="d-flex justify-center mt-4 align-center blue--text heading">
      Available Properties 
    </div>
    <v-row class="px-2 d-flex no-gutters justify-center">  
      <v-col class="col-12">
        <diani-sunrise-two />
      </v-col>
      <v-col class="col-12">
        <diani-sunrise />
      </v-col>
      <v-col class="col-12">
        <diani-crystal />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import dianiCrystal from '../properties/dianiCrystal.vue';
  import dianiSunrise from '../properties/dianiSunrise.vue';
  import dianiSunriseTwo from '../properties/dianiSunriseTwo.vue';

  export default{
    name: 'propertiesCard',
    components:{
      dianiCrystal,
      dianiSunrise,
      dianiSunriseTwo
    },
    data(){
      return{
        counter: 1
      }
    },
    methods:{
      viewAll(){
        this.$router.push('/properties')
      }
    }
  }
</script>