<template lang="html">
    <div class="grey lighten-3">

    <v-form ref="form" class="pa-4">
      <p style="font-size: 2.3rem;"> add property</p>
      <!-- {{getMyProperty}} -->
      <v-text-field
      placeholder="name"
      v-model="property.name"
      label="name"
      type="name"
      outlined
      clearable />
      <v-text-field
      placeholder="location"
      v-model="property.location"
      label="name"
      type="name"
      outlined
      clearable />
      <v-textarea
      placeholder="description"
      v-model="property.description"
      label="description"
      type="name"
      outlined
      clearable />
      <v-file-input
      @change="registerImages"
      outlined
      multiple
      label="Images"
      accept="image/*,"
      ></v-file-input>
      <div  class="d-flex justify-end">
        <v-btn class="success" @click="submitProperty" :loading="adding"> submit </v-btn>
      </div>
    </v-form>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AddProperty',
  computed: {
    ...mapGetters(['getAddPropertyStep', 'getAddedProperty', 'getAddedUnitsDetails', 'getMyProperty', 'getPropertyTypes', 'getUnitTypes']),
    electricity_token () {

      if (this.property.electricity_type == "token") {
      
        return true
      
      }
      
      return false
    },

    typesOfProperty () {
      const types  = []
      this.getPropertyTypes.forEach(type => {
        types.push(type.name)
      });
      return types
    },

    typesOfUnits () {
      const types  = []
      this.getUnitTypes.forEach(type => {
        types.push(type.name)
      });
      return types
    }
  },
  data(){
    return{
      adding: false,
      formdata: null,
      property: {
        vacancy: 1
      },
      units: {},
      unitsNomenclature: [
        'Alphabetic',
        'Numeric'
      ],
      additional_characteristic: true,
      getLocations: [
        'Embassy',
        'Bomas',
        'Nyeri view',
        'Nyeri town',
        "King'ong'o",
        "Skuta",
        "Classic",
        "Nyaribo",
        "Kangemi",
        'Kakuru'
      ],
      characteristic: {},
      characteristics: [],
      stand_alone_type: true,
      new_property_type: null
    }
  },
  methods:{
    ...mapActions(['addProperty', 'addUnits', 'setAddPropertyStep', 'addPropertyImages', 'addUnitsImages', 
    'addUnitsDetails', 'toogleInfoBox', 'fetchMyProperty', 'addPropertyDetails', 'getTypes']),
    startOver(){
      this.adding = false
      this.formdata = null
      this.property = {vacancy: 1}
      this.units = {}
      this.additional_characteristic = true
      this.characteristic = {}
      this.characteristics = []
      this.stand_alone_type = true
      this.new_property_type = null
      this.setAddPropertyStep(1)
    },
     finish(){
      this.adding = false
      this.formdata = null
      this.property = {vacancy: 1}
      this.units = {}
      this.additional_characteristic = true
      this.characteristic = {}
      this.characteristics = []
      this.stand_alone_type = true
      this.new_property_type = null
      this.$router.push('/dashboard')
      this.setAddPropertyStep(1)

    },
    skip(){
      this.setAddPropertyStep(this.getAddPropertyStep + 1)
    },
    registerImages(event){
      var fd = new FormData()
      let images = event.length
      for (let i = 0; i < images; i++) {
          fd.append('images[]', event[i])
      }
      this.formdata = fd;
    },
    fixImageUrl(url){
      return process.env.VUE_APP_API + '/storage/' + url
    },
    registerCharacteristic(){
      this.characteristics.push(this.characteristic)
      this.characteristic = {}
      this.additional_characteristic = false
    },
    setStandAloneType(event){
      const characteristic = {
        label: 'type',
        value: event
      }
      this.characteristics.push(characteristic)
      this.stand_alone_type = false

      console.log(event)
      console.log(this.characteristics)
    },
    removeCharacteristic(characteristic_index){
      const characteristics = []
      
      if(this.characteristics[characteristic_index].label == 'type'){
        this.stand_alone_type = true
      }
      for (let index = 0; index < this.characteristics.length; index++) {


        if(characteristic_index != index){
          characteristics.push(this.characteristics[index])
        }
        
      }
      this.characteristics = characteristics
      if(!this.characteristics[0]){
        this.additional_characteristic = true
      }
    },
    submitProperty () {
      this.adding = true
      console.log(this.property)
      switch (this.getAddPropertyStep) {
        case 1:
          this.addProperty(this.property).then((res) => {
            this.adding = res
          })
          break;

        case 2:
          //image??
          this.formdata.append('property_id', this.getAddedProperty.id)
          this.formdata.append('property_name', this.getAddedProperty.name)
          this.addPropertyImages(this.formdata).then((res) => {
            this.adding = res
            this.formdata = null
          })
          break;
          
        case 3:

          const data = {
            property_id: this.getAddedProperty.id,
            name_type: this.units.name_type,
            name_prefix: this.units.prefix ? this.units.prefix : null,
            name_suffix: this.units.suffix ? this.units.suffix : null,
            type: this.units.type,
            tenants: this.units.tenants,
            rent: this.units.rent,
            quantity: this.units.quantity
          }
          console.log(data)
          const units = []
          units.push(data)
          console.log(units)
          this.addUnits(units).then((res) => {
            this.adding = res
          })

        case 4:
          this.formdata.append('unit_ids', this.getAddedUnitsDetails.units_ids)
          this.addUnitsImages(this.formdata).then((res) => {
            this.adding = res
          })
          break;

        case 5:
          if(this.getAddedProperty.type == 'Stand-Alone'){
            let details = this.characteristics.filter(c => (c.label != 'type'))

            const data = {
              property_id: this.getAddedProperty.id,
              details: details,
              type: this.new_property_type
            }
            console.log(data)
            // console.log('la')
            // console.log(la)
            // console.log('la')
            this.addPropertyDetails(data).then((res) => {
              this.adding = res
            })

          } else {
            const data = {
              property_id: this.getAddedProperty.id,
              details: this.characteristics,
              type: this.getAddedUnitsDetails.type
            }
            console.log(this.characteristics)
            this.addUnitsDetails(data).then((res) => {
              this.adding = res
            })
          }
          break;
          
        default:
          break;
      }
    },

  },

  mounted(){
    if(this.getAddPropertyStep === 1){
       const data = {
        status: true,
        information: 'Here you are to add basic inforamtion in the property. It is the first of several steps'
      }
      this.toogleInfoBox(data)
      //get property types and unit types
      this.getTypes()
    }
  },
  beforeDestroy(){
    const data = {
      status: false,
      information: null
    }
    this.toogleInfoBox(data)
  }
}
</script>
<style lang="css" scoped>
.stripped{
  background-color: #E0E0E0;
}
.stripped:nth-child(even){
  background-color:#F5F5F5;
}
  
</style>
