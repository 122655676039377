<template>
<div class="px-2 my-2"
  style="z-index:02;"> 
    <div>
      <v-img class="img rounded lighten-4 ma-0 animate__animated animate__fadeIn "
      v-if="counter < 3"
      contain
      :src="require(`../../assets/banners/01.jpeg`)"
      />
      <v-img class="img rounded lighten-4 ma-0 animate__animated animate__fadeIn "
      v-if="counter == 3"
      contain
      :src="require(`../../assets/banners/02.png`)"
      />
      <v-img class="img rounded lighten-4 ma-0 animate__animated animate__fadeIn "
      v-if="counter == 4"
      contain
      :src="require(`../../assets/banners/03.png`)"
      />
      <v-img class="img rounded lighten-4 ma-0 animate__animated animate__fadeIn "
      v-if="counter == 5"
      contain
      :src="require(`../../assets/banners/04.png`)"
      />
      <v-img class="img rounded lighten-4 ma-0 animate__animated animate__fadeIn "
      v-if="counter == 6"
      contain
      :src="require(`../../assets/banners/05.png`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'topPic',
  data(){
    return {
      counter: 0
    }
  },
  methods:{
    
  },
  mounted(){
    setInterval(() => {
      if(this.counter < 6){
        this.counter ++
      } else {
        this.counter = 0
      }
    }, 4000);
  }
}
</script>

