<template>
  <div class="no-gutters mt-4 pt-4">
    <div class="d-flex align-center justify-center animate__animated animate__zoomIn blue--text heading">
      Available Properties
    </div>
    <v-row class="px-2 d-flex no-gutters justify-center">  
      <v-col class="col-12">
        <diani-sunrise-two />
      </v-col>
      <v-col class="col-12">
        <diani-sunrise />
      </v-col>
      <v-col class="col-12">
        <diani-crystal />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import dianiCrystal from '../../components/properties/dianiCrystal.vue';
  import dianiSunrise from '../../components/properties/dianiSunrise.vue';
  import dianiSunriseTwo from '../../components/properties/dianiSunriseTwo.vue';

  export default {
    name: 'ViewAllProperties',
    components:{
      dianiCrystal,
      dianiSunrise,
      dianiSunriseTwo
    },
    data(){
      return {
        count: 30
      }
    }
  }
</script>
