var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"my-4",staticStyle:{"background-color":"#48aaf912"}},[_c('v-card-title',{staticClass:"d-flex justify-center text-center"},[_c('p',{staticClass:"my-0 heading",staticStyle:{"width":"100%","color":"#ec2027"}},[_vm._v(" Diani Sunrise Estate ")])]),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{staticClass:"col-12 py-2"},[_c('span',{staticClass:"pointer",staticStyle:{"position":"absolute","top":"35%","left":"5%","z-index":"99"},on:{"click":_vm.previousPhoto}},[_c('v-icon',{staticClass:"rounded grey"},[_vm._v(" mdi-arrow-left ")])],1),(_vm.img_source == 'sunrise_0.png')?_c('v-img',{staticClass:"rounded lighten-4 mx-1",attrs:{"contain":"","src":require("../../assets/sunrise_0.png")}}):_vm._e(),(_vm.img_source == 'sunrise_1.png')?_c('v-img',{staticClass:"rounded lighten-4 mx-1",attrs:{"contain":"","src":require("../../assets/sunrise_1.png")}}):_vm._e(),(_vm.img_source == 'sunrise_2.png')?_c('v-img',{staticClass:"rounded lighten-4 mx-1",attrs:{"contain":"","src":require("../../assets/sunrise_2.png")}}):_vm._e(),(_vm.img_source == 'sunrise_3.png')?_c('v-img',{staticClass:"rounded lighten-4 mx-1",attrs:{"contain":"","src":require("../../assets/sunrise_3.png")}}):_vm._e(),(_vm.img_source == 'sunrise_4.png')?_c('v-img',{staticClass:"rounded lighten-4 mx-1",attrs:{"contain":"","src":require("../../assets/sunrise_4.png")}}):_vm._e(),_c('span',{staticClass:"pointer",staticStyle:{"position":"absolute","top":"35%","right":"5%"},on:{"click":_vm.nextPhoto}},[_c('v-icon',{staticClass:"rounded grey"},[_vm._v(" mdi-arrow-right ")])],1)],1),_c('v-col',{staticClass:"col-12 d-flex justify-center "},[_c('v-img',{staticClass:"rounded mx-1",class:{
                'red': _vm.img_source == 'sunrise_0.png'
                },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/sunrise_0.png")},on:{"click":function($event){return _vm.changeSource('sunrise_0')}}}),_c('v-img',{staticClass:"rounded mx-1",class:{
                'red': _vm.img_source == 'sunrise_1.png'
                },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/sunrise_1.png")},on:{"click":function($event){return _vm.changeSource('sunrise_1')}}}),_c('v-img',{staticClass:"rounded mx-1",class:{
                'red': _vm.img_source == 'sunrise_2.png'
                },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/sunrise_2.png")},on:{"click":function($event){return _vm.changeSource('sunrise_2')}}}),_c('v-img',{staticClass:"rounded mx-1",class:{
                'red': _vm.img_source == 'sunrise_3.png'
                },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/sunrise_3.png")},on:{"click":function($event){return _vm.changeSource('sunrise_3')}}}),_c('v-img',{staticClass:"rounded mx-1",class:{
                'red': _vm.img_source == 'sunrise_4.png'
                },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/sunrise_4.png")},on:{"click":function($event){return _vm.changeSource('sunrise_4')}}})],1),_c('v-col',{staticClass:"col-12 px-4 text--bold",staticStyle:{"font-weight":"900"}},[_c('h3',{staticClass:"d-flex align-center mr-2"},[_c('v-icon',{staticClass:"red--text",attrs:{"small":""}},[_vm._v(" mdi-pin ")]),_vm._v(" Diani ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("Value Added - Fenced and Gated.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("1Km off Lungalunga Highway.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("4KM to the Diani Whitesands Beach.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("7KM to Ukunda Airstrip.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("Near Diani 5-Star Hotels.")+" ")],1)]),_c('v-col',{staticClass:"col-12 d-flex px-4"},[_c('v-col',{staticClass:"col-5 offset-1"},[_c('span',{staticClass:"d-flex mr-2 cta tex-center d-flex justify-center align-center red rounded white--text"},[_c('span',{staticClass:"body-text px-3"},[_vm._v(" "+_vm._s("549,000")+" ")])])]),_c('v-col',{staticClass:"col-5 offset-1"},[_c('span',{staticClass:"d-flex mr-2 pointer cta tex-center d-flex justify-center align-center red rounded white--text",on:{"click":function($event){return _vm.go('property/diani-sunrise-estate')}}},[_c('span',{staticClass:"body-text px-3"},[_vm._v(" "+_vm._s("View")+" ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }