<template>
  <div class="mt-4 bold-2" style="z-index: 9;">
    <v-list
      class=" text-uppercase"
      nav
    >
      <v-list-item-group
        class="row no-gutters"
        active-class="deep-blue--text white--text"
      >
      
        <v-list-item @click="goToSection('home')">
          {{'home'}}
        </v-list-item>
        
        <v-list-item @click="goToSection('about')">
          {{'about us'}}
        </v-list-item>
        
        <v-list-item @click="goToSection('properties')">
          {{'properties'}}
        </v-list-item>

        <v-list-item @click="goToSection('about')">
          {{'how to buy'}}
        </v-list-item>
        
        <v-list-item @click="goToSection('faqs')">
          {{'FAQ'}}
        </v-list-item>
        
        <v-list-item @click="goToSection('blogs')">
          {{'blogs'}}
        </v-list-item>
        
        <v-list-item @click="goToSection('contact')">
          {{'Contact Us'}}
        </v-list-item>
        
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'homeNavdrawer',
  computed:{
    ...mapGetters(['getUser'])
  },
  methods:{
    go(code){
      // window.location.href = code
      this.$router.push(code)
    },
    goToSection(element_id){
      this.go('/' + element_id)
    },
  }
}
</script>
